<template>
  <transactionTracker/>
  <navBar/>
  <div class="pageWrapper">
    <div id="starBack" :style="{transform: pagePosition(0.75), transition: transitionDuration(1.25)}"> <starBackground/> </div>

    <img id="foreGround" src="./assets/vvpNiI-Y.gif" :style="{transform: pagePositionX(1), transition: transitionDuration(1.1)}"/>

    <div id="gridContainer">
       <div id="pageGrid" :style="{transform: pagePosition(1), transition: transitionDuration(1)}">

         <!-- Left page  -->
        <div class="page" id="page1"> 
          <transition name='fade'>
            <div v-if="currentPage==1">
              <!-- Content -->
              <provPage :changePageFunc="changePage" :enableMobileNav="enableMobileNavBtns"/>
            </div>
          </transition>  
        </div>

        <!-- Center page  -->
        <div class="page" id="page2">  
          <landingPage :changePageFunc="changePage"/>
        </div>

        <!-- Right page  -->
        <div class="page" id="page3" >  
          <transition name='fade'>
            <div  v-if="currentPage==3">
              <!-- Content -->
              <aboutPage :changePageFunc="changePage" :enableMobileNav="enableMobileNavBtns"/>
            </div>
          </transition>
        </div>

        <!-- Top page  -->
        <transition name='fade'>
          <div class="page" id="page4" v-if="currentPage==4">  
            <!-- Content -->
            <purchasePanel :changePageFunc="changePage" style="margin: 5%"/>
            <btn :btn_text="'Cancel'" @click="changePage(2)" style="margin: 5%"/>
          </div>
        </transition>

       </div>
    </div>
  </div>
</template>

<script>
import btn from "./components/button.vue"
import navBar from "./components/navBar.vue"
import purchasePanel from "./components/pages/purchase_panel.vue"
import transactionTracker from "./components/transactionTracker.vue"
import starBackground from "./components/star_background.vue"

import landingPage from "./components/pages/landing_page.vue"
import aboutPage from "./components/pages/about_page.vue"
import provPage from "./components/pages/provHash_page.vue"

export default {
  name: 'App',
  components: {
    navBar, landingPage, purchasePanel, btn, transactionTracker, aboutPage, provPage, starBackground
  },
  data() { return {
    xMoveamount: 25,
    yMoveamount: 100,
    currentPage: 2,
    BasetransitionDuration: 0.75,
    enableMobileNavBtns: false,
  }},
  methods:{
    resizeHandler(){
      this.BasetransitionDuration = 0;
      if(window.innerWidth <= 1000){
        this.xMoveamount = 100;
        this.enableMobileNavBtns = true; 
      }else{
        this.xMoveamount = 25;
        this.enableMobileNavBtns = false;
      }
    },
    changePage(pg){
      this.BasetransitionDuration = 0.85;
      if(this.currentPage == pg) { this.currentPage = 2; }
      else { this.currentPage = pg; }
    },
  },
  computed:{
    pagePosition () { //1 - normal, 0.5 = half speed, 0.25 = quater speed
      if (this.currentPage < 4){
        return (_multiplier => 'translate(-' + ((this.currentPage * (this.xMoveamount * _multiplier)) - (this.xMoveamount * _multiplier)) + 'vw, -100vh)')
      }
      return (_multiplier => 'translate(-' + ((2 * (this.xMoveamount * _multiplier)) - (this.xMoveamount * _multiplier)) + 'vw, 0vh)')
    },
    pagePositionX () { //1 - normal, 0.5 = half speed, 0.25 = quater speed
      if (this.currentPage < 4){
        return (_multiplier => 'translate(-' + ((this.currentPage * (this.xMoveamount * _multiplier)) - (this.xMoveamount * _multiplier))+ 'vw, 0)')
      }
      return (_multiplier => 'translate(-' + ((2 * (this.xMoveamount * _multiplier)) - (this.xMoveamount * _multiplier))+ 'vw, 100vh)')
    },
    transitionDuration(){
      return( _multiplier => 'transform ' + this.BasetransitionDuration*_multiplier + "s" )
    }
  },
  mounted(){
    this.resizeHandler();
  },
  created(){
    window.addEventListener("resize", this.resizeHandler);
  },
}
</script>

<style>
@font-face {
  font-family: 'CaveStoryRegular';
  src: local("fonts"), url("/fonts/Cave-Story.woff2") format("woff2"), url("/fonts/Cave-Story.woff") format("woff");
}

#app {
  font-family: 'CaveStoryRegular';
}

:root{
  user-select: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body{
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: black;

  min-height: 100%;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.pageWrapper{
  position:fixed;
  top: 0;
  left: 0;
  overflow: 0;
  height: 100%;
  width: 100%;
}

#gridContainer{
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;
}

#pageGrid{
  display: grid;
  grid-template-columns: 1fr, 0.75fr, 1fr;
  grid-template-rows: repeat(2, 100vh);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  
  width: 150vw;
  height: 200vw;
}

#foreGround{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150%;
  max-height: 150%
}

.page{
  min-width: 50vw;
  height: calc(100% - clamp(44px, 6vh, 58px));
  /* Padding for navbar */
  padding-top: clamp(44px, 6vh, 58px); 
}

#page1 { 
  grid-area: 2 / 1 / 3 / 2;
}

#page2{
  grid-area: 2 / 2 / 3 / 3;
}

#page3{
  grid-area: 2 / 3 / 3 / 4;
}

#page4{
  grid-area: 1 / 2 / 2 / 3;
  height: 80%;
  justify-content:flex-start;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 999px) {
  #starBack{
    width: 300vw;
  }
  
 #pageGrid{
    grid-template-columns: 1fr, 1fr, 1fr;
    width: 300vw;
  }

  .page{
    min-width: 100vw;
    max-width: 100vw;
  }

  #foreGround{
    width: 300vw;
  }
}

@supports(padding:max(0px)) {
    body, header, footer {
        padding-right: calc(20px + constant(safe-area-inset-right));
        padding-left: calc(20px + constant(safe-area-inset-left));
    }
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.8s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

</style>

