<template>
    <transition name='fade'>
        <div id="_tracker_container" v-if="trackerEnabled">
            <div id="_tracker">
                <div id="_trackerHeader"> TRANSACTION STATUS </div>
                <br>
                <div id="trackerText" v-if="position < 2 & !txFailed"> Completing Transaction...<br> do not close or refresh! </div>
                <div id="trackerText" v-if="position == 2 & !txFailed"> Transaction Confirmed<br> Thank you! </div>
                <div id="trackerText" v-if="txFailed"> Transaction Failed!<br> View transaction for more info! </div>
                <div id="tracker_grid">
                    <div id="progress_bar"> <div id="progress_bar_fill" :style="{'height': progressbarHeight}"></div> </div>
                    <div id="item3" class="trackerItem"> <div v-if="position >= 0"> Generated X </div> <div v-else> Generating... </div> </div>
                    <div id="item2" class="trackerItem"> <div v-if="position >= 1"> Processed X </div> <div v-else> Processing... </div> </div>
                    <div id="item1" class="trackerItem" v-if="!txFailed"> <div v-if="position == 2"> Confirmed X </div> <div v-else> Confirming... </div> </div>
                    <div id="item1" class="trackerItem" v-else> <div style="color: red"> Failed! </div> </div>
                </div>
                <br>
                <div id="transactionID" @click="openEtherscan">Open in Etherscan </div><br>
            </div>
            <br>
            <transition name='fade'>
                <div style="height: 32px; display: flex; justify-content:center; align-items:center;">
                    <btn :btn_text="'Close'" @click="exitTracker()" v-if="closeButtonEnabled"/>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import {checkTx} from "../assets/web3Scripts"
import btn from "./button.vue"

export default {
    data(){return{
        position: 0,
        txFailed: false,
        trackerEnabled: false,
        closeButtonEnabled: false,
    }},
    components:{ btn },
    computed:{
        progressbarHeight(){
            var borderOffset = '4px'
            var result = '0%';
            if(this.position == 1) { result = 'calc(50% - ' + borderOffset + ")"  }
            if(this.position == 2) { result = 'calc(100% - ' + borderOffset + ")"   }
            return result;
        },
        hash(){
            return this.$store.state.transactionInfo.hash
        }
    },
    watch:{
        txFailed(){
            alert("Transaction has failed!\nPlease look up transaction ID for more information\nTxID: " + this.$store.state.transactionInfo.hash); this.closeButtonEnabled = true;
        },
        hash(){
            this.trackerEnabled = true;
            this.position = 1;
            this.checkTransaction();
        },
        position(){
            if(this.position == 2){ this.closeButtonEnabled = true; }
        }
    },
    methods:{
        openEtherscan(){
            window.open("https://etherscan.io/tx/" + this.$store.state.transactionInfo.hash);
        },
        async checkTransaction(){
            const interval = setInterval(async() => {
                var _result = await checkTx();
                if(_result != null){
                    if (_result.status) { this.position = 2; }
                    else{ this.txFailed = true; }
                    clearInterval(interval);
                }
            }, 1000);
        },
        exitTracker(){
            this.txFailed = false;
            this.closeButtonEnabled = false;
            this.trackerEnabled = false
        }
    }
}
</script>

<style scoped>
#_tracker_container{
    z-index: 11;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#_tracker{
    background: black;
    outline: solid rgb(119, 252, 223) 4px;
    height: clamp(250px, 40vw, 450px);
    width: clamp(200px, 50vw, 450px);

    display: flex;
    flex-direction: column;
    align-items: center;
}

#_trackerHeader{
    height: 10%;
    width: 100%;
    background: rgb(119, 252, 223);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(22px, 4.5vw, 36px);
}

#trackerText{
    color: white;
    height: 15%;
    font-size: clamp(22px, 5vw, 46px);
    text-align: center;
}

#tracker_grid{
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    height: 75%;
    width: 100%;
    margin-right: 20%;
}

#progress_bar{
    grid-area: 1 / 2 / 4 / 3;

    margin: auto;
    height: 80%;
    width: 50%;
    outline: solid rgb(255, 255, 255) 4px;
}

#progress_bar_fill{
    height: 0%;
    width: calc(100%-4px);
    border: inset rgb(0, 0, 0) 2px;
    background: rgb(119, 252, 223);
    transition: height 0.5s;
}

#item3{
    grid-area: 1 / 1 / 2 / 2;
}

#item2{
    grid-area: 2 / 1 / 3 / 2;
}

#item1{
    grid-area: 3 / 1 / 4 / 2;
}

.trackerItem{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: rgb(119, 252, 223);
    font-size: clamp(22px, 5vw, 46px);
}

#transactionID{
    color: white;
    text-align: center;
    font-size: clamp(20px, 4.5vw, 38px);
}

#transactionID:hover{
    color: lightgray;
    cursor: pointer;
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
