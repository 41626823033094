<template>
    <div id="_purchaseMenuContainer">
        <div id="_purchaseMenuHeader"> Mint UFO </div>

        <div id="_purchaseMenuBody">
            <div id="_publicSaleInfo" >
                <div class="_purchaseMenuInfo" style="text-align: center"> Remaining Mints: {{ this.$store.state.ufoInfo.mintsAvailiable }} </div> <br>
                <p class="_purchaseMenuInfo"> Price: 0.00 eth</p>
                <p class="_purchaseMenuInfo"> Maximum order: 5 </p>
            </div>
            
            <div>
                <div id="_purchaseMenuAmountContainer">
                    <div id="_purchaseMenuButton" @click="addToAmount(-1)">  <p style="margin: 3px 0 0 3px"> - </p> </div>
                    <div id="_purchaseMenuAmount"> {{ purchaseAmount }} </div>
                    <div id="_purchaseMenuButton" @click="addToAmount(1)"> <p style="margin: 5px 0 0 5px"> + </p> </div>
                </div>

                <div id="_maxOrderTextContainer" style="height: 16px">
                    <div v-if="displayMaxOrderText" class="_purchaseMenuInfo" style="text-align: center; color:red;"> Max amount! </div> <br>
                </div>
            </div>
        
            <div class="_purchaseMenuInfo" style="text-align: center;"> Total: 0.00 eth </div> 

        </div>

        <div id="purchaseMenuFooter">
            <div id="purchaseButton" @click="mintUFO(this.purchaseAmount)" v-if="enablePurchaseBtn">
                Mint!
            </div>
            <div id="purchaseButton_unclickable" v-else>
                Mint!
            </div>
        </div>
        
    </div>
    
</template>

<script>
import { mintUFO, setUFOmints } from  "../../assets/web3Scripts"

export default{
    props:{
        changePageFunc: Function, 
    },
    data(){return{
        purchaseAmount: 0,
        displayMaxOrderText: false,
        openTransactionCover: false,
        enablePurchaseBtn: false,
        maxPerOrder: 5,
    }},
    methods:{
        mintUFO,
        addToAmount(_value){
            var amount = this.purchaseAmount + _value;
            if(amount < 0 || amount > 5) { return }
            else if(amount > this.$store.state.ufoInfo.mintsAvailiable) { this.displayMaxOrderText = true; return; }
            else if(amount > this.maxPerOrder) { this.displayMaxOrderText = true; return; }
            else if(amount == this.$store.state.ufoInfo.mintsAvailiable) { this.displayMaxOrderText = true; }
            else this.displayMaxOrderText = false;
            this.purchaseAmount = amount;

            if(this.purchaseAmount == 0 && !this.PendingTx) { this.enablePurchaseBtn = false; }
            else if(!this.PendingTx){ this.enablePurchaseBtn = true;  }
        }
    },
    computed:{
        // totalCost(){
        //     var _price;
        //     if(this.$store.state.saleState == 'public'){ _price =  this.$store.state.publicInfo.price}
        //     if(this.$store.state.saleState == 'whitelist'){ _price =  this.$store.state.whitelistInfo.price}
        //     var _result = _price * this.purchaseAmount
        //     return _result
        // },
        wallet(){
            return this.$store.state.connectedWallet.address
        },
        PendingTx(){ return this.$store.state.transactionInfo.pending },
        txHash() { return this.$store.state.transactionInfo.hash  },
        remaining() { return  this.$store.state.amountRemaining;}
    },
    watch:{
        wallet(){
            this.changePageFunc(2);
            this.purchaseAmount = 0;
        },
        txHash(){
            this.changePageFunc(2);
            this.purchaseAmount = 0;
        },
        PendingTx(){
            this.enablePurchaseBtn = !this.PendingTx;
        },
    },
    mounted(){
        setUFOmints();
    }
}
</script>

<style scoped>
#_purchaseMenuContainer{
    width: clamp(240px, 85vw, 400px);
    height: clamp(240px, 90vw, 432px);
    background: rgb(0, 0, 0);
    outline: solid rgb(119, 252, 223) 4px;
}

#_purchaseMenuHeader{
    height: 8%;
    background: rgb(119, 252, 223);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(16px, 8vw, 42px);
}

#_purchaseMenuBody{
    height: calc(92% - 25px);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

._purchaseMenuInfo{
    color: white;
    font-size: clamp(24px, 8vw, 38px);
    margin: 0 0 0 6px;
}

#_purchaseMenuAmountContainer{
    height: clamp(80px, 35vw, 175px);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

#_purchaseMenuAmount{
    height: 100%;
    width: clamp(80px, 35vw, 175px);
    background: rgb(0, 0, 0);
    color: rgb(119, 252, 223);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(48px, 35vw, 180px);
    outline: solid rgb(119, 252, 223) 2px;
    user-select: none;
}

#_purchaseMenuButton{
    background: black;
    outline: solid rgb(119, 252, 223) 2px;
    height: clamp(32px, 16vw, 64px);
    width: clamp(32px, 16vw, 64px);
    color: rgb(119, 252, 223);
    font-size: clamp(24px, 25vw, 82px);
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: background-color 0.2s;
}

#_purchaseMenuButton:hover{
    cursor: pointer;
    background: rgb(39, 39, 39);
}

#purchaseMenuFooter{
    height: 50px;
    display: flex;
    justify-content: center;
}

#purchaseButton{
    position: absolute;
    height: 50px;
    background: black;
    outline: solid rgb(119, 252, 223) 4px;
    width: clamp(140px, 16vw, 164px);
    margin: auto;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(24px, 8vw, 36px);
}

#purchaseButton_unclickable{
    position: absolute;
    height: 50px;
    background: black;
    outline: solid rgb(119, 252, 223) 4px;
    width: clamp(140px, 16vw, 164px);
    margin: auto;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(24px, 8vw, 36px);
    filter: brightness(20%);
    user-select: none;
}

#purchaseButton:hover{
    cursor: pointer;
    background: rgb(39, 39, 39);
}

</style>
