<template>
    <div id="_buttonContainer">
        <p> {{ btn_text }} </p>
    </div>
</template>

<script>
export default {
    props:{
        btn_text: String,
    },
}
</script>

<style scoped>
#_buttonContainer{
    border: solid rgb(119, 252, 223) 3px;
    background: black;
    user-select: none;
    white-space: pre-line;

    display: flex;
    justify-content: center;
    align-items: center;

    width: clamp(110px, 32vw, 180px);
    transition: background-color .4s;


}

#_buttonContainer p{
    color: rgb(119, 252, 223);
    margin: 5px;
    text-align: center;
    font-size: clamp(1.3rem, 6vw, 2rem);
}

#_buttonContainer:hover{
    cursor: pointer;
    background: rgb(39, 39, 39);
}
</style>