<template>
    <div id="_pageContainer">
        <div id="_textContainer"> 
            Remaining Aliens: 0
        </div>

        <br>
        <div id="_logoContainer">
            <img id="_logo" src="../../assets/PixelAliens.png">
        </div>

        <br>
        <div id="navButtons">
            <btn :btn_text="'Provenance Hash'" @click="changePageFunc(1)"/>
            <btn :btn_text="'About \n Pixel Aliens'" @click="changePageFunc(3)"/>
        </div>
        <br>

        <div id="_textContainer">
            <!-- Sales Have Permanently Closed. <br> -->
            <br> <a href="https://opensea.io/collection/pixel-aliens-by-serpentipity" target="_blank" style="text-decoration: underline; color: rgb(119, 252, 223)"> View On Opensea</a>
        </div>
        
        <br> <br>

        <div id="_purchaseMenuContainer" v-if="enablePurchaseButton" >
            <btn :btn_text="'Mint UFO'" @click="changePageFunc(4)" />
        </div>

    </div>


</template>

<script>
import btn from "../button.vue"
import { setUFOmints } from "../../assets/web3Scripts"

export default{
    components:{ 
        btn, 
    },
    props:{
        changePageFunc: Function,
    },
    computed:{
        enablePurchaseButton(){
            if (!this.mintingEnabled) { return false }
            if (this.$store.state.connectedWallet.address == '') { return false }
            if (this.mintsRemaining <= 0) { return false }
            return true;
        },
        mintsRemaining(){
            return this.$store.state.ufoInfo.mintsAvailiable;
        },
        mintingEnabled(){
            return this.$store.state.ufoInfo.mintingEnabled;
        }
    },
    mounted(){
        setUFOmints();
    }
}
</script>

<style scoped>
#_pageContainer{
    padding-top: 10px;
    width: 100%;
    height: 100%;
    max-width: clamp(250px, 75vw, 1200px);
    max-height: 500px;

    margin: auto;
}

#_textContainer{
    text-align: center;
    font-size: clamp(16px, 8vw, 42px);
    color: rgb(119, 252, 223);
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
}

#_logoContainer{
    width: 100%;
    height: fit-content;

    display: flex;
    justify-content: center;
    align-items: center;
}

#_logo{
    height: auto;
    width: 75%;
    max-height: 90%;   
}

#navButtons{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
}

#_purchaseMenuContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7%;
}

@media screen and (max-width: 999px) {
  #_logo{
    width: 90%;
    }

    #_pageContainer{
        height: 50%;
    }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
