<template>
    <div class="pageContainer">
        <div class="textPanelContainer">
            <!-- Heading here -->
            <textPanel :headings="'PROVENANCE HASH'">
                <!-- Content here -->
                <slot> 
                    <br>
                    <h1> WHAT IS A PROVENANCE HASH? </h1>
                    <p class="_panelText"> A provenance hash is a series of SHA256-hashes combined in sequence (1,2,3) and hashed again to produce a final SHA-256 hash that is unique to the order of the original hashes. If the order is changed or a hash is 
                    altered, the final resulting hashes will not be the same. </p>
                    <p class="_panelText"> Utilizing this, all Pixel Aliens have been hashed in their original sequence (1,2,3) to produce a hash unique to this sequence. Once sales have completed, the Pixel Aliens contract will generate an 
                    offset that will be applied to the original sequence (see bottom for example) to produce another unique hash. These hashes can then be checked by anyone to confirm the validity of both the original, and offset Hashes. This will confirm if the images or 
                    their order have been manipulated to alter the allocation of image to token.
                    </p>
                    <p class="_panelText" style="font-size: clamp(16px, 1.2vw, 32px)"> IPFS CID: QmbEXEjv1TDQrjjwteFiuhFbm4zg49K8zNoZrdpuNboUkp </p>
                    <hr>
                    <p class="_panelText">Offset = 14<br>Original sequence: 1, 2, 3, 4<br>
                    New sequence: 15, 16, 17, 18</p>
                </slot>

            </textPanel>

            <div id="buttonContainer">
                <btnSqr :btn_text="'Back'" @click="changePageFunc(2)" v-if="enableMobileNav"/>
            </div>
        </div>

    </div>
</template>

<script>
import textPanel from "../text_panel.vue"
import btnSqr from "../button.vue"

export default{
    components: { textPanel, btnSqr},
    setup() {
        
    },
    props:{
        changePageFunc: Function,
        currentPage: Number,
        enableMobileNav: Boolean,
    }
}
</script>

<style scoped>
.pageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: clamp(300px, 60vh, 800px);

    user-select:text;
}

.textPanelContainer{
    height: clamp(300px, 90%, 800px);
    width:  clamp(500px, 30vw, 700px);
}

.textPanelContainer h1{
    font-size: clamp(26px, 2vw, 38px);
    font-weight: normal;
    margin: 0;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
}

#buttonContainer{
    margin-top: 10px;
    float: right;
}

#teamContainer{
    display: flex;
    justify-content: space-between;
    height: 25%;
    width: calc(100% - 15px);
    max-width: 500px;
    margin: 0 auto;
    padding: 5px;

}

#teamContainer img{
    height: 10vh;
    padding: 3px;
    background: rgb(255, 255, 255);
    border: solid black 2px
}

#teamText{
    width: 70%;
    text-align: center;
    margin-top: 5px;
}

#teamTextBioWrapper{
    height: calc(80% - 3px);
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

._panelText{
    font-size: clamp(22px, 1.5vw, 36px);
    margin: 0 0 5% 0;
}


@media screen and (max-width: 600px) {
 .pageContainer{
    justify-content: initial;
    height: clamp(300px, 80vh, 800px);
 }

 .textPanelContainer{
     margin-top: 15%;
     height: 60%;
     width: 80%;
 }

 .textPanelContainer{
    transform: translateY(0%);
    }
}
</style>