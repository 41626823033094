<template>
    <div id="_navBarContainer">
        <div id="_iconBarContainer"> <icon_bar/> </div>
        <btn class='connectBTN' @click="connectClicked" :btn_text="'Connect'"  v-if="wallet == ''"/>
        <btn class='connectBTN' :btn_text="wallet.substring(1,8) + '...'"  v-else/>

        <transition name="fade">
        <div id="_providerOptionsContainer" v-if="providerOptionsPanelOpen">
            <div id="_providerOptionsBack" @click="this.providerOptionsPanelOpen = false"></div>

            <div id="_providerOptionsPanel">
                <div class="providerOption" id='provider_metamask' @click="connect_metaMask">
                    <img src="../assets/metamask-horizontal.png"/>
                </div>

                <div class="providerOption" id='provider_walletconnect' @click="connect_walletConnect">
                    <img src="../assets/walletconnect-banner.png"/>
                </div>
            </div>
        </div>
        </transition>

    </div>
</template>

<script>
import btn from "./button.vue"
import icon_bar from "./Icon_bar.vue"
import { connect_walletConnect,connect_metaMask } from "../assets/web3Scripts.js"


export default{
    components: {
        btn, icon_bar, 
    },
    data(){return{
        providerOptionsPanelOpen: false,
        popUpOpen: false,
    }},
    methods: {
        connect_metaMask, connect_walletConnect,
        connectClicked(){
            if(this.$store.state.connectedWallet.address != '') { return }

            if(this.$store.state.hasMetaMask){
                this.providerOptionsPanelOpen = true;
            }
            else{
                this.connect_walletConnect();
            }
        }
    },
    computed:{
        wallet(){ return  this.$store.state.connectedWallet.address},
    },
    watch:{
        wallet(){
            this.providerOptionsPanelOpen = false;
        },
    }
}
</script>

<style scoped>
#_navBarContainer{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: clamp(44px, 6vh, 58px);
    background: rgb(119, 252, 223, 0.05);

    display: flex;
    justify-content: space-between;
    align-items: center;
}

#_iconBarContainer{
    padding-left: 5px;
    width: clamp(140px, 20vh, 240px);
    height: clamp(32px, 5vh, 54px);
    margin: 2px;
}

#_providerOptionsContainer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

#_providerOptionsBack{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);

}

#_providerOptionsPanel{
    z-index: 10;
    width: 80vw;
    height: 30vh;
    max-width: 600px;
    max-height: 300px;
    min-width: 100px;
    min-height: 200px;
    background: rgb(119, 252, 223);
    border: solid rgb(119, 252, 223) 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
}

.providerOption{
    z-index: 10;
    height: calc(50% - 2px);
    width: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s ease-out;
}

.providerOption:hover{
    cursor: pointer;
    background: rgb(29, 29, 29);
}

.providerOption img{
    transform: scale(85%);
    height: auto;
    width: 100%;
}

#provider_walletconnect img{
    margin-right: 24px;
}

#_textPanelContainer{
    width: clamp(250px, 50vw, 400px);
}

.connectBTN{
    margin-right: 4px; 
    height: 65%;
}

/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
