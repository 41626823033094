<template>
  <div class="star_background">

  </div>
</template>

<script>
    

    export default {
      mounted(){
        this.generateStars();
      },
      methods:{
        generateStars(){
          var i  = 0;
          var amount = 250;
          var bGround = document.getElementById("starBack");
          var width = 300;
          var height = 200;

          while( i < amount){
              var star = document.createElement("star");

              var rot = Math.random() * 180;
              var scale = Math.random() * 5;
              var delay = Math.random() * 4;

              star.style.left = Math.floor(Math.random() * width) +'vw';
              star.style.top = Math.floor(Math.random() * height) +'vh';

              star.style.transform = 'rotate('+rot+'deg)';
              star.style.width = scale+'px';
              star.style.height = scale+'px';
              star.style.animationDelay = delay+'s';

              bGround.appendChild(star);
              i++;
          }
        }
      }
    }
</script>

<style>

.star_background{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
}

star{
  position: absolute;
  background: rgb(221, 221, 209);
  min-width: 2px;
  min-height: 2px;

  animation: shine;
  animation-duration: 6s;
  transition: all 0.4s;
  transition: transform 0.4s ease-in-out;
  animation-iteration-count: infinite;

}

@keyframes shine{
  
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(0.1) rotate(180deg);
  }
  100%{
    transform: scale(1.2);
  }
  
}
</style>