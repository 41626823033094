import { createStore } from 'vuex'
export default createStore({
    state: {
        // amountRemaining: 'Loading...',
        // saleState: '',
        hasMetaMask: false,
        targetChain: 1,
        connectedWallet: {
            address: '',
            network: '',
        },
        contractInfo: {
            address: '0x35636d12d8181F9B958503De4Fbcb1C8bc4809AC',
            InfuraHTTPS: 'https://mainnet.infura.io/v3/8be3f94a1b9742728306ffa870d1249a',
            InfuraWSS: 'wss://mainnet.infura.io/ws/v3/8be3f94a1b9742728306ffa870d1249a',
            abi: require('../assets/abi.json'), 
        },

        ufoInfo:{
            mintsAvailiable: 0,
            mintingEnabled: false
        },
        
        transactionInfo:{
            pending: false,
            successful: '',
            hash: '',
        }
        
        // whitelistInfo: {
        //     onWhiteList: false,
        //     price: 0.03,
        //     remainingPurchases: 0,
        // },
        
        // publicInfo: {
        //     price: 0.05,
        //     maxPerOrder: 5,
        // },
    },
    mutations: {},
    actions: {},
    modules: {},
})