import NodeWalletConnect from "@walletconnect/node";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";
import Web3 from 'web3';
import store from '../store/index.js'

// Setup walletConnect
const walletConnector = generateWalletConnector();
const web3 = new Web3(new Web3.providers.HttpProvider(store.state.contractInfo.InfuraHTTPS));
const contractObj = new web3.eth.Contract(store.state.contractInfo.abi, store.state.contractInfo.address);
if (typeof window.ethereum !== 'undefined') { store.state.hasMetaMask = true; }
checkIfLoggedIn();
InitializeListeners();
initializeData();


export function connect_walletConnect() {
    // Check if connection is already established
    if (!walletConnector.connected) {
        // create new session
        walletConnector.createSession().then(() => {
            // get uri for QR Code modal
            const uri = walletConnector.uri;
            // display QR Code modal
            WalletConnectQRCodeModal.open(
                uri,
                () => {
                    console.log("QR Code Modal closed");
                },
                true // isNode = true
            );
        });
    }
}

export async function connect_metaMask() {
    await window.ethereum.request({ method: 'eth_requestAccounts' }).then(Response => {
        setconnectedWallet(Response[0], window.ethereum.networkVersion);
    }).catch(e => {
        console.log(e.message)
    });
}

function generateWalletConnector() {
    return new NodeWalletConnect({
        bridge: "https://bridge.walletconnect.org", // Required
    }, {
        clientMeta: {
            description: "WalletConnect NodeJS Client",
            url: "https://nodejs.org/en/",
            icons: ["https://nodejs.org/static/images/logo.svg"],
            name: "WalletConnect",
        },
    });
}

function InitializeListeners() {
    // Add metamask listeners
    if (store.state.hasMetaMask) {
        // Add listener
        window.ethereum.on('accountsChanged', accounts => {
            if (accounts.length > 0) { setconnectedWallet(accounts[0], window.ethereum.networkVersion); } else { setconnectedWallet('', '') }
        })
    }


    //walletConnect connection listener
    walletConnector.on("connect", (error, payload) => {
        if (error) {
            throw error;
        }
        WalletConnectQRCodeModal.close(true);
        const { accounts } = payload.params[0];
        setconnectedWallet(accounts[0], walletConnector.chainId);
    });

    //walletConnect disconnect listener
    walletConnector.on("disconnect", (error) => {
        if (error) {
            throw error;
        }
        setconnectedWallet('', '');
    });
}

async function checkIfLoggedIn() {
    if (walletConnector.connected) {
        setconnectedWallet(walletConnector.accounts[0], walletConnector.chainId);
    }
}

async function initializeData() {
    // Set websocket to listen for events
    web3.setProvider(new Web3.providers.WebsocketProvider(store.state.contractInfo.InfuraWSS));

    // // Set initial amount Remaining and listen for new mints
    // store.state.amountRemaining = (3333 - await contractObj.methods.totalSupply().call());
    // contractObj.events.Transfer({ from: '0x0000000000000000000000000000000000000000000000000000000000000000' , fromBlock: 'latest'}, () => {
        
    // }).on('data', (event) => { 
    //     store.state.amountRemaining = 3333 - event['returnValues'][2];
    // })
    // // 

    // //Set sale state and listen for updates
    setSaleState(await contractObj.methods.mintingEnabled().call());
    // contractObj.events.SaleStateChanged().on('data', (event) => { setSaleState(event.returnValues['_value']); setWhiteListStatus(); })
}

// Sets the mintingState 
function setSaleState(_value) {
    store.state.ufoInfo.mintingEnabled = _value
}

async function setconnectedWallet(_address, network) {
    store.state.connectedWallet.address = _address;
    store.state.connectedWallet.network = network
    setUFOmints();
    // setWhiteListStatus();
}

export async function setUFOmints(){
    if (store.state.connectedWallet.address != '') {
        var _address = store.state.connectedWallet.address;
        var response = await contractObj.methods.checkAllocatedMints(_address).call();
        store.state.ufoInfo.mintsAvailiable = response;
    }
    else{
        store.state.ufoInfo.mintsAvailiable = 0;
    }
}


export async function mintUFO(_quantity){
    if(store.state.connectedWallet.address == ''){ alert("Error: No wallet connected!"); return }
    if(_quantity <= 0){ alert("Error: Invalid amount entered!"); return}
    store.state.transactionInfo.pending = true;

    // Hard gas limit, if required
    // var gasLimit = 0;
    // if(_quantity == 1) { gasLimit = 120000 }
    // else if (_quantity == 2) { gasLimit = 140000 }
    // else if (_quantity == 3) { gasLimit = 250000 }
    // else if (_quantity == 4) { gasLimit = 350000 }
    // else if (_quantity == 5) { gasLimit = 400000 }
    
    if(walletConnector.connected){
        // Check the user is on the correct network
        if(walletConnector.chainId == store.state.targetChain){ 
            const tx = {
                from: store.state.connectedWallet.address, // Required
                to: store.state.contractInfo.address, // Required (for non contract deployments)
                data: '40380836000000000000000000000000000000000000000000000000000000000000000' + _quantity.toString(), //WalletConnect MintToken ABI
                // gasLimit: gasLimit,
                value: 0
            };
            walletConnector
            .sendTransaction(tx)
            .then((result) => {
                store.state.transactionInfo.hash = result;
            })
            .catch((error) => {
                alert(error);
            });
            await setUFOmints();
            store.state.transactionInfo.pending = false;
        }
        else{
            alert("Error: Please connect to the Main Network!");
        }
    }
    else{
        if(window.ethereum.chainId == store.state.targetChain) { 
            // send over metaMask
            
            const web4 = new Web3(window.ethereum);
            const new_cont = new web4.eth.Contract(store.state.contractInfo.abi, store.state.contractInfo.address)
            
            await new_cont.methods.claimUFO(_quantity).send({
                value: 0,
                from: store.state.connectedWallet.address,
                // gasLimit: gasLimit
            }).on('transactionHash', function(hash){ store.state.transactionInfo.hash = hash;}).catch((error) => console.log(error));
            
        }
        else{
            alert("Error: Please connect to the Main Network!");
        }
    }
    await setUFOmints();
    store.state.transactionInfo.pending = false;
    
}

export async function checkTx(){
    var tx = store.state.transactionInfo.hash;
    var result = await web3.eth.getTransactionReceipt(tx);
    return result;
}

// // sets if the account is on the whitelist & How many purchases remain
// export async function setWhiteListStatus() {
//     if (store.state.connectedWallet.address != '') {
//         var _addr = store.state.connectedWallet.address;
//         var response = await contractObj.methods.WHITELIST(_addr).call();
//         store.state.whitelistInfo.remainingPurchases = 3 - response[0]
//         store.state.whitelistInfo.onWhiteList = response[1];
//     } else {
//         store.state.whitelistInfo.remainingPurchases = 0;
//         store.state.whitelistInfo.onWhiteList = false;
//     }
// }

// export async function mintToken(_quantity) {
    //     if(store.state.connectedWallet.address == ''){ alert("Error: No wallet connected!"); return }
    //     if(_quantity <= 0){ alert("Error: Invalid amount entered!"); return}
    //     store.state.transactionInfo.pending = true;
    
    //     // Generate total price, based on the salestate
    //     var totalPrice;
    //     if (store.state.saleState == 'whitelist') { totalPrice = (store.state.whitelistInfo.price * _quantity) } 
    //     else if (store.state.saleState == 'public') { totalPrice = (store.state.publicInfo.price * _quantity) }
    //     var weiValue = web3.utils.toWei(totalPrice.toString(),'ether');
    
    //     // Hard gas limits
    //     var gasLimit = 0;
    //     if(store.state.saleState == 'whitelist')
    //     {
        //         if(_quantity == 1) { gasLimit = 200000 }
//         else if (_quantity == 2) { gasLimit = 350000 }
//         else if (_quantity == 3) { gasLimit = 450000 }
//     }

//     else if(store.state.saleState == 'public')
//     {
//         if(_quantity == 1) { gasLimit = 200000 }
//         else if (_quantity == 2) { gasLimit = 350000 }
//         else if (_quantity == 3) { gasLimit = 450000 }
//         else if (_quantity == 4) { gasLimit = 550000 }
//         else if (_quantity == 5) { gasLimit = 700000 }
//     }
        
    
//     if(walletConnector.connected){
//         // Check the user is on the correct network
//         if(walletConnector.chainId == store.state.targetChain){ 
//             const tx = {
//                 from: store.state.connectedWallet.address, // Required
//                 to: store.state.contractInfo.address, // Required (for non contract deployments)
//                 data: 'c634d032000000000000000000000000000000000000000000000000000000000000000' + _quantity.toString(), //WalletConnect MintToken ABI
//                 gasLimit: gasLimit,
//                 value: weiValue
//             };
//             walletConnector
//             .sendTransaction(tx)
//             .then((result) => {
//                 store.state.transactionInfo.hash = result;
//             })
//             .catch((error) => {
//                 alert(error);
//             });
//             store.state.transactionInfo.pending = false;
//         }
//         else{
//             alert("Error: Please connect to the Main Network!");
//         }
//     }
//     else{
//         if(window.ethereum.chainId == store.state.targetChain) { 
//             // send over metaMask

//             const web4 = new Web3(window.ethereum);
//             const new_cont = new web4.eth.Contract(store.state.contractInfo.abi, store.state.contractInfo.address)

            
//             await new_cont.methods.mintToken(_quantity).send({
//                 value: weiValue,
//                 from: store.state.connectedWallet.address,
//                 gasLimit: gasLimit
//             }).on('transactionHash', function(hash){ store.state.transactionInfo.hash = hash;}).catch((error) => console.log(error));

//         }
//         else{
//             alert("Error: Please connect to the Main Network!");
//         }
//     }
//     store.state.transactionInfo.pending = false;
// }
