<template>
    <div class="pageContainer">
        <div class="textPanelContainer">
            <!-- Heading here -->
            <textPanel :headings="'ABOUT PIXEL ALIENS'">
                <!-- Content here -->
                <slot> 
                    <br>
                    <h1> WHAT ARE PIXEL ALIENS? </h1>
                    <p class="_panelText">Pixel Aliens are a community driven, generative art collection drawn in 69x69 that have invaded the Ethereum blockchain. While some traits are inspired by your favorite blue chip NFTs, 
                    Aliens and traits are unique and hand-drawn by legendary Australian anime tattooist <a href="https://www.instagram.com/serpentipity/" target="_blank">@serpentipity</a>, check out his work on Instagram.</p>
                    
                    <h1> HOW MANY PIXEL ALIENS ARE THERE? </h1>
                    <p class="_panelText">Total supply is 355 Aliens with 10 preminted for giveaways. </p>
                    
                    <h1> WHEN WERE THEY AVAILIABLE TO MINT? </h1>
                    <p class="_panelText"> Presale - 11th January 10pm UTC <br> Public sales - 12th January 10pm UTC </p>

                    <h1> HOW MUCH DID THEY COST TO MINT? </h1>
                    <p class="_panelText">0.03 ETH for whitelist/OG <br>
                    0.05 ETH for the public</p>
                    
                    <h1> WHERES YOUR ROADMAP? </h1>
                    <p class="_panelText"> You can find our roadmap and all project info in our <a href="https://www.discord.gg/pixelaliens" target="_blank">Discord</a>. </p>
                    
                    <h1> WHERES YOUR CONTRACT? </h1>
                    <p class="_panelText"> Our contract can be found <a href="https://etherscan.io/token/0x1a96787f93a784848f069273906d0804a6f8a6d9"> on etherscan </a> </p> 
                    
                    <h1> WHAT RIGHTS DO I HAVE TO THE ARTWORK? </h1>
                    <p class="_panelText"> You are free to do whatever you want with your Pixel Alien. </p>


                    <hr>
                    <div id="teamContainer">
                        <img src="../../assets/serpentipity.png"/>
                        <div id="teamText">
                            <h1> SERPENTIPITY </h1> 
                            <div id="teamTextBioWrapper">
                                Pixel Aliens Illustrator And Professional Tattoo Artist
                            </div>
                        </div>
                    </div>
                    <br>
                    <div id="teamContainer">
                        <img src="../../assets/De_train.png"/>
                        <div id="teamText">
                            <h1> DE_TRAIN </h1> 
                            <div id="teamTextBioWrapper">
                                Community Coordinator And Administrator
                            </div>
                        </div>
                    </div>
                    <br>
                    <div id="teamContainer">
                        <img src="../../assets/Xardoux.png"/>
                        <div id="teamText">
                            <h1> XARDOUX </h1> 
                            <div id="teamTextBioWrapper">
                                Website And Contract Developer
                            </div>
                        </div>
                    </div>

                </slot>

            </textPanel>

            <div id="buttonContainer">
                <btnSqr :btn_text="'Back'" @click="changePageFunc(2)" v-if="enableMobileNav"/>
            </div>
        </div>

    </div>
</template>

<script>
import textPanel from "../text_panel.vue"
import btnSqr from "../button.vue"

export default{
    components: { textPanel, btnSqr},
    setup() {
        
    },
    props:{
        changePageFunc: Function,
        currentPage: Number,
        enableMobileNav: Boolean,
    }
}
</script>

<style scoped>
.pageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: clamp(200px, 60vh, 800px);
}

.textPanelContainer{
    height: clamp(300px, 90%, 800px);
    width:  clamp(500px, 30vw, 700px);
}

.textPanelContainer h1{
    font-size: clamp(26px, 2vw, 38px);
    font-weight: normal;
    margin: 0;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
}

#buttonContainer{
    margin-top: 10px;
    float: left;
}

#teamContainer{
    display: flex;
    justify-content: space-between;
    height: 25%;
    width: calc(100% - 15px);
    max-width: 500px;
    margin: 0 auto;
    padding: 5px;

}

#teamContainer img{
    height: 10vh;
    padding: 3px;
    background: rgb(255, 255, 255);
    border: solid black 2px
}

#teamText{
    width: 70%;
    text-align: center;
    margin-top: 5px;
    font-size: clamp(22px, 2vw, 36px);
}

#teamTextBioWrapper{
    height: calc(80% - 3px);
    width: 100%;
    font-size: clamp(20px, 1.8vw, 34px);
    display: flex;
    justify-content: center;
    align-items: center;
}

._panelText{
    font-size: clamp(22px, 1.5vw, 36px);
    margin: 1% 0 5% 0;
}


@media screen and (max-width: 600px) {
 .pageContainer{
    justify-content: initial;
    height: clamp(300px, 80vh, 800px);
 }

 .textPanelContainer{
     margin-top: 15%;
     height: 60%;
     width: 80%;
 }

 .textPanelContainer{
    transform: translateY(0%);
    }
}
</style>
