<template>
    <div class="_textPanelContainer">

        <div id="_textPanelHeader">  
            <p id="_textPanelHeadingText"> {{headings}} </p> 
        </div>

        <div id="_testPanelSlotContainer"> 
            <p id="_textPanelSlotText">
                <slot/>
            </p>
        </div>

    </div>
</template>

<script>
export default{
    props:{
        headings: String,
    }
}
</script>

<style scoped>
._textPanelContainer{
    width: 100%;
    height: 100%;
    border: solid rgb(119, 252, 223) 4px;
    background: rgb(119, 252, 223);
    box-sizing: border-box;
}

#_textPanelHeader{
    height: 20px;
    background: #77fcdf;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(26px, 5vw, 36px);
}

#_textPanelHeadingText{
    margin: 0;
}

#_testPanelSlotContainer{
    height: calc(100% - 22px);
    background-color: #4ea895;
    border: solid black 1px;
    overflow-y: scroll;
    overflow-x: hidden;
    text-align: center;
}

#_textPanelSlotText{
    font-size: clamp(20px, 5vw, 26px);
    margin: 4px 4px;
}

#_outline{
    color: rgb(255, 255, 255);
    text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000, 1px 1px 0 #000000;
}

</style>
