<template>

<div class="icon_grid">
    <!-- Icons and links -->
    <img class="icons" id="icon_twitter" :src="icon_data.Twitter.Icon"    style="height:75%"   @mouseenter="switchPopUp(icon_data.Twitter.PopUp_text)" @mouseleave="switchPopUp()" @click="OpenLink(icon_data.Twitter.Link)"/>
    <img class="icons" id="icon_discord" :src="icon_data.Discord.Icon"    style="height:100%"  @mouseenter="switchPopUp(icon_data.Discord.PopUp_text)" @mouseleave="switchPopUp()" @click="OpenLink(icon_data.Discord.Link)"/>
    
    <div class="icon_wrapper" @mouseenter="switchPopUp(icon_data.Etherscan.PopUp_text)" @mouseleave="switchPopUp()" @click="OpenLink(icon_data.Etherscan.Link)">
        <img class="icons" id="icon_etherscan" :src="icon_data.Etherscan.Icon"  style="height:85%"   />
    </div>

    <div class="icon_wrapper" @mouseenter="switchPopUp(icon_data.OpenSea.PopUp_text)" @mouseleave="switchPopUp()" @click="OpenLink(icon_data.OpenSea.Link)">
        <img class="icons" id="icon_opensea" :src="icon_data.OpenSea.Icon"    style="height:85%"  />
    </div>

    <!-- Context popup on hover -->
    <transition name='PopIn'>
      <div class="PopUpContainer" v-if="Show_popUp">
          <p id="popUP_text"> {{PopUp_text}} </p>
      </div>
    </transition>
</div>
    
</template>

<script>
export default {
  components: {
    
  },
  data(){
    return{
        PopUp_text: "Placeholder text",
        Show_popUp: false,
        icon_data:{
        Twitter:    {Icon: require("../assets/Logos/twitter-64.png") ,   Link: "https://twitter.com/pixelaliens_NFT",    PopUp_text: "Follow us on twitter"},
        Discord:    {Icon: require("../assets/Logos/Discord-Logo.png") ,   Link: "https://www.discord.gg/pixelaliens",    PopUp_text: "Join us on Discord"},
        Etherscan:  {Icon: require("../assets/Logos/etherscan-logo.png") , Link: "https://etherscan.io/token/0x1a96787f93a784848f069273906d0804a6f8a6d9",   PopUp_text: "Probe contract"},
        OpenSea:    {Icon: require("../assets/Logos/OpenSea-Logo.png") ,   Link: "https://opensea.io/collection/pixel-aliens-by-serpentipity",     PopUp_text: "View collection"},
        },
    }
  },
  methods:{
    OpenLink(target_url){
      window.open(target_url, '_blank').focus();
    },
    switchPopUp(popupText){
      this.Show_popUp = !this.Show_popUp;
      this.PopUp_text = popupText;
    }
  }
}
</script>

<style scoped>
.icon_grid{
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  height: 100%;
  width: 100%;
}

#icon_discord{
  margin-top: 4px;
}

.icon_wrapper{
  height: 100%;
  transform: scale(0.92);
  margin-top: 8px;
}

.icons{
  width: auto;
  transition: transform 0.2s ease-out;
  user-select: none;  
}

.icons:hover{
  filter: brightness(80%);
  transform: translateY(-2px);
  cursor: pointer;
}

.PopUpContainer{
  position: absolute;
  top: 3.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: fit-content;
}

#popUP_text{
  padding: 2px 10px;
  font-size: 1.5em;
  color: rgb(119, 252, 223);
  border: solid rgb(119, 252, 223) 2px;
  background: black;
  user-select: none;
}

.PopIn-enter-active, .PopIn-leave-active {
  transition: opacity .5s;
}
.PopIn-enter, .PopIn-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
